@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Barlow+Condensed:wght@400;600;900&family=Parisienne&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Flaticon', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Franklin Gothic Medium', 'Arial Narrow', sans-serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Sango-Light';
  src: local('Sango-Light'), url(/static/media/Sango-Light.a369672e.otf) format('truetype');
}

@font-face {
  font-family: 'Flaticon';
  src: local('Flaticon'), url(/static/media/Flaticon.9d41344f.ttf) format('truetype');
}
.row-header {
  margin: 0 auto;
  padding: 0 auto;
}
.journalSectionHeading{
  color: red;
  padding: 2em 0 1em;
  font-size: 3em;
}
.row-content {
  margin: 0 auto;
  padding: 50px 0;
  border-bottom: 1px ridge;
  min-height: 400px;
}

.jumbotron {
  padding: 180px 0 70px !important;
  margin: 0 auto !important;
  background-image: url(/static/media/faq.8bd6a7d9.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  z-index: 1;
  border-radius: 0% 0% 50% 50% / 0% 0% 15% 15% !important;
}

address {
  font-size: 80%;
  margin: 0px;
  color: #0f0f0f;
}

.btn_2 {
  display: inline-block;
  padding: 14px 30px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 25px;
  transition: 0.5s;
  background-color: #0b025f;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
}
.btn_3 {
  display: inline-block;
  padding: 14px 30px;
  border-radius: 50px;
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  margin-top: 25px;
  transition: 0.5s;
  border :2px solid #0b025f;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
}

.btn_1 {
  display: inline-block;
  padding: 14px 30px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 25px;
  transition: 0.5s;
  background-color: #4dbb39;
  font-weight: 500;
  letter-spacing: 1px;
}

.btn_3:hover{
  background-color: #0b025f;
  color:#FFF;
}
.btn_1:hover,
.btn_2:hover {
  color: #4dbb39;
  background-color: #fff;
  text-decoration: none;
}

.about_text {
  padding: 20px 0 0;
}

.faq_part {
  padding: 40px 0;
  color: #000000;
}

.blockquote0 {
  background-color: #000f52;
  color: white;
  padding: 10px 0 10px 25px;
}

.blockquote1 {
  background-color: #000f52;
  padding: 0 0 30px 80px;
}

.homeSection {
  margin: 6em 2em;
}

.sectionImage {
  margin: 10px;
}

.faqs {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.faqs .faq {
  margin: 15px 5px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;

  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 30px;
  height: 30px;

  background-image: url(/static/media/arrow-down-mint.488731ae.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

.servicesSection {
  margin: 5em 2em 2em;
}
.serviceCont {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;
}
.services {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
  text-align: center;
  font-size: 16px;
  padding: 20px 0;
  min-height: 400px;
  transition: 0.3s all ease;
}
.services:hover {
  background-color: #4dbb39;
  color: #fff;
}
.services:hover .line {
  background-color: #fff;
}
.services i {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.entinner {
  padding: 0 20px;
  text-align: center;
}

.services .line {
  height: 3px;
  width: 25%;
  background-color: #fe5f28;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-bottom: 1em;
}
.services h4 {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600 !important;
  text-transform: uppercase;
  font-weight: bold;
}
.services p {
  margin-top: 1em;
  text-align: justify;
}

@media only screen and (max-width: 992px) {
  .serviceCont {
    grid-template-columns: repeat(1, 1fr);
  }
  .services {
    min-height: 0;
  }
}

@media only screen and (max-width: 768px) {
  .homeSection {
    margin: 3em 1.5em;
  }

  .servicesSection {
    margin: 5em 1.5em 2em;
  }
}

.banner_text_iner {
  color: antiquewhite;
  font-family: "Sango-Light";
}
.h3 {
  margin-right: 150px;
}
.typewriter h3 {
  text-align: center;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The Typewriter Cursor */
  white-space: normal; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.1em; /* Adjust as needed */
  -webkit-animation: typing 5s steps(80, end) forwards, blink-caret 0.75s step-end 40;
          animation: typing 5s steps(80, end) forwards, blink-caret 0.75s step-end 40;
}

/* The Typing Effect */
@-webkit-keyframes typing {
  from {
    width: 0%;
  }
  to {
    width: 100%;
    border: none;
  }
}
@keyframes typing {
  from {
    width: 0%;
  }
  to {
    width: 100%;
    border: none;
  }
}

/* The Typewriter Cursor Effect */
@-webkit-keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.page-enter {
  position: fixed;
  opacity: 0.01;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: all 300ms ease-out;
}

.loginText {
  font-family: "Poppins", sans-serif;
}
.headerBtn {
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #191d34;
  color: #fff;
  font-size: 0.9em;
  white-space: nowrap;
  outline: none !important;
  cursor: pointer;
  transition: 0.3s all ease;
}
.headerBtn:hover {
  color: #191d34;
  background-color: #fff;
}
.ctaBtn {
  padding: 10px 45px;
  border-radius: 25px;
  background-color: #4dbb39;
  color: #fff;
  font-family: "Poppins", sans-serif;
  border: none;
  outline: none !important;
  cursor: pointer;
  transition: 0.3s all ease;
}
.ctaBtn:hover {
  background-color: #fff;
  color: #4dbb39;
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .loginText {
    font-size: 0.8em;
  }
}

.breadcrumbCont {
  list-style-type: none;
  display: flex;
}
.breadcrumbCont li {
  position: relative;
}
.breadcrumbCont li:not(:first-child) {
  margin-left: 1.5em;
}
.breadcrumbCont li:not(:last-child)::after {
  position: absolute;
  content: ">";
  top: 50%;
  right: -1em;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.breadcrumbCont li a {
  color: #007bff;
  text-decoration: none;
}

.personCard {
  position: relative;
  width: 300px;
  box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
  border-radius: 10px;
  margin: 0 auto 1.5em;
  overflow: hidden;
}
.personImage {
  width: 100%;
  max-height: 360px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: 0.3s all ease;
}
.personDetails {
  color: #000;
  text-decoration: none;
  text-align: center;
  padding: 20px 20px 7px;
}
.personDetails a {
  color: #000;
  text-decoration: none;
}
.personPost {
  font-weight: bold;
}
.personBranch {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 126, 95, 0.9);
  border-radius: 10px;
  color: #fff;
  top: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  transition: 0.3s all ease;
}
.cta {
  display: block;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  border-radius: 5px;
  padding: 10px 0;
  width: 170px;
  margin: 1em auto 0;
  font-size: 0.8em;
  transition: 0.3s all ease;
}
.cta:hover {
  background-color: #fff;
  color: #ff7e5f;
  text-decoration: none;
}
.personCard:hover .personBranch {
  top: 0;
  opacity: 1;
  pointer-events: all;
}

.imageCard {
  width: 300px;
  margin: 3em 0;
}

.imageCard .background {
  position: relative;
  height: 15em;
  margin: 0 0 1em;
}

.imageCard img {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  padding: 0 1em 0;
  bottom: 0;
  left: 0;
}
/*# sourceMappingURL=ImageComponent2.css.map */
.headerTitleCont {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
  padding: 0 15px;
}

.headerTitleCont::after {
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.headerTitleCont .animation {
  -webkit-animation: appear 0.4s linear;
          animation: appear 0.4s linear;
  z-index: 2;
}

@-webkit-keyframes appear {
  from {
    opacity: 0;
    margin-top: 5em;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes appear {
  from {
    opacity: 0;
    margin-top: 5em;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

.headerTitleCont .headerTitle {
  font-size: 2.5em;
}

.headerTitleCont .headerAuthor {
  font-weight: 200;
  font-size: 1.3em;
}

.headerTitleCont .headerDate {
  font-size: 1em;
  font-weight: 200;
}

@media only screen and (min-width: 992px) {
  .headerTitleCont {
    height: 40vh;
  }
  .headerTitleCont .headerTitle {
    font-size: 3.5em;
  }
  .headerTitleCont .headerAuthor {
    font-size: 2em;
  }
  .headerTitleCont .headerDate {
    font-size: 1.1em;
  }
}
/*# sourceMappingURL=HeaderTitle.css.map */
.aboutus-container {
  margin: 20px;
}
.title {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: normal;
  margin-top: 1em;
}
.title h2 {
  position: relative;
  font-size: 2em;
}
.title h2::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 50%;
  background-color: #ff7e5f;
  bottom: -0.1em;
  left: 0;
}
.aboutText {
  text-align: justify;
}

.personCard {
  position: relative;
  width: 300px;
  box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
  border-radius: 10px;
  margin: 0 auto 1.5em;
  overflow: hidden;
}
.personImage {
  width: 100%;
  max-height: 360px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: 0.3s all ease;
}
.personDetails {
  color: #000;
  text-decoration: none;
  text-align: center;
  padding: 20px 20px 7px;
}
.personDetails a {
  color: #000;
  text-decoration: none;
}
.personPost {
  font-weight: bold;
}
.personBranch {
  position: absolute;
  height: 100%;
  width: 100%;
  /* background-color: rgba(255, 126, 95, 0.9); */
  background-color: rgba(0, 123, 255, 0.9);
  border-radius: 10px;
  color: #fff;
  top: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: space-evenly;
  padding: 3rem 0;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  transition: 0.3s all ease;
}
.cta {
  display: block;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  border-radius: 5px;
  padding: 10px 0;
  width: 170px;
  margin: 1em auto 0;
  font-size: 0.8em;
  transition: 0.3s all ease;
}
.cta:hover {
  background-color: #fff;
  /* color: #ff7e5f; */
  color: #007bff;
  text-decoration: none;
}
.personCard:hover .personBranch {
  top: 0;
  opacity: 1;
  pointer-events: all;
}

.socials {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 2rem;
}

.socials > a {
  color: white;
}
.socials > a:hover {
  color: white;
}

.personBranch p:nth-child(2) {
  width: 60%;
}

.header-logo {
  margin-left: 30px;
}

.header-logo-img {
  height: 60px;
  width: 100px;
}

@media screen and (max-width: 999px) and (min-width: 750px) {
  .header-logo-img {
    height: 50px;
    width: 80px;
  }
}

.navbar-nav {
  align-items: center;
}
.nav-btn {
  color: black;
  margin: 0 10px;
  padding: 20px 10px;
}
.navbar-toggler {
  background: #b6b6b6;
}
.nav-btn:hover {
  color: #ff7e5f;
  text-decoration: none;
  transition: 0.5s;
}
.banner_text_iner {
  color: antiquewhite;
}

.page-enter {
  position: fixed;
  opacity: 0.01;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: all 300ms ease-out;
}

.nav-link {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.2em;
  text-transform: uppercase;
  white-space: nowrap;
}

/* @media screen and (max-width: 1000px) {
  .nav-link {
    font-size: 16px;
  }
} */

@media screen and (max-width: 992px) and (min-width: 768px) {
  .nav-link {
    font-size: 0.9em;
  }
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10000000;
}

.header .navbarTop {
  height: 10vh;
  background-color: #191d34;
  color: #fff;
  font-family: "Barlow Condensed", sans-serif;
  z-index: 10000001;
}

.header .navbarTop .mobileNavLogo {
  display: none;
}

.header .navbarTop .navList {
  display: flex;
  padding: 20px;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  z-index: 10000002;
}

.header .navbarTop .navList img {
  width: 100px;
}

.header .navbarTop .navList a {
  display: inline;
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 792px) {
  .header .navbarTop {
    position: relative;
    z-index: 5;
  }
  .header .navbarTop .mobileNavLogo {
    position: absolute;
    display: block;
    width: 100px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .header .navbarTop .hamburger {
    position: absolute;
    top: 50%;
    left: 3%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    z-index: 10000003;
  }
  .header .navbarTop .hamburger .line {
    height: 3px;
    background-color: #fff;
    margin: 5px;
  }
  .header .navbarTop .hamburger .line1 {
    width: 30px;
  }
  .header .navbarTop .hamburger .line2 {
    width: 20px;
  }
  .header .navbarTop .hamburger .line3 {
    width: 27px;
  }
  .header .navbarTop .navList {
    position: fixed;
    background-color: #191d34;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    -webkit-clip-path: circle(0px at 0% -10%);
            clip-path: circle(0px at 0% -10%);
    transition: 0.65s all ease;
    pointer-events: none;
  }
  .header .navbarTop .navList img {
    display: none;
  }
  .header .navbarTop .navList.open {
    -webkit-clip-path: circle(1500px at 0% -10%);
            clip-path: circle(1500px at 0% -10%);
    pointer-events: all !important;
  }
}
/*# sourceMappingURL=NavbarComponent.css.map */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.btn {
  margin: 10px 10px 0 0;
}

.app {
  width: 400px;
  margin: 0 auto;
  height: 100vh;
}

/* Contact.js */
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4em;
}

textarea {
  padding: 20px;
  border-radius: 3px;
  margin: 20px 0;
  border: 1px solid lightgray;
  max-width: 1400px;
  height: 150px;
  outline: none;
}

textarea:focus {
  border: 1px solid #000;
}

.form > button {
  padding: 10px 45px;
  background-color: #02026e;
  border-radius: 5px;
  color: #fff;
  margin-top: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

.input-group {
  display: flex;
  flex-direction: column-reverse;
  margin: 0.5em 0;
}

.input-group.half {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
}

.input-group.w75p {
  width: 75%;
  margin: 1em auto;
}

.input-group.w50p {
  width: 50%;
  margin: 1em auto;
}

.input-group.w25p {
  width: 25%;
  margin: 1em auto;
}

.input-group input {
  background-color: inherit;
  color: #000;
  font-size: 1em;
  padding: 30px 10px 10px;
  border: none;
  border-bottom: 2px solid darkgray;
  outline: none;
  transition: 0.4s all ease;
}

.input-group input::-webkit-input-placeholder {
  opacity: 0;
}

.input-group input:-ms-input-placeholder {
  opacity: 0;
}

.input-group input::placeholder {
  opacity: 0;
}

.input-group input:not(:-ms-input-placeholder) {
  border-bottom: 2px solid #000;
}

.input-group input:focus,
.input-group input:not(:placeholder-shown) {
  border-bottom: 2px solid #000;
}

.input-group input:not(:-ms-input-placeholder) ~ label {
  -webkit-transform: translate(10px, -35px) scale(0.9);
  transform: translate(10px, -35px) scale(0.9);
  padding: 0;
  color: #000;
}

.input-group input:focus ~ label,
.input-group input:not(:placeholder-shown) ~ label {
  -webkit-transform: translate(10px, -35px) scale(0.9);
  transform: translate(10px, -35px) scale(0.9);
  padding: 0;
  color: #000;
}

.input-group label {
  position: absolute;
  padding-left: 10px;
  margin-bottom: 2px;
  color: darkgray;
  cursor: pointer;
  -webkit-transform: translate(4px, -14px) scale(1.02);
  transform: translate(4px, -14px) scale(1.02);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  font-family: "Poppins";
  font-weight: normal;
  transition: 0.4s all ease;
}

.input-group select {
  border: none;
  margin: 1.5em 0 0;
  border-bottom: 2px solid #000;
  cursor: pointer;
  outline: none;
  padding-bottom: 14px;
  padding-left: 10px;
}

.input-group button {
  padding: 5px 45px;
  background-color: #191d34;
  border: 1px solid #191d34;
  border-radius: 4px;
  color: #fff;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}

.contactus-container {
  margin: 20px;
}

.contactus-container div .landing {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  align-items: center;
}

.contactus-container div .landing .content {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
}

.contactus-container div .landing .content .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.5em;
}

.contactus-container div .landing .content .text {
  font-size: 1em;
  color: #616161;
  margin: 7px 0 0 15px;
  text-align: justify;
}

.contactus-container div .landing .content .text a button {
  padding: 7px 30px;
  background-color: #c2caf7dc;
  color: #191d34;
  border: 1px solid #c2caf7dc;
  border-radius: 5px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.contactus-container div .landing .content .text a:hover button {
  background-color: #fff;
  border: 1px solid #191d34;
}

.contactus-container div .landing .content .text a:last-child {
  margin-left: 1em;
}

.contactus-container div .landing img {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .contactus-container div .landing {
    margin: 0 5vw;
    height: 90vh;
    -ms-grid-columns: (45% 55%)[1];
        grid-template-columns: repeat(1, 45% 55%);
  }
  .contactus-container div .landing .content {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
  }
  .contactus-container div .landing img {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1;
  }
  .contactus-container div .partners {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
}
/*# sourceMappingURL=ContactUsStyles.css.map */
.emdeb-responsive {

}
.members-container {
  position: relative;
  margin: 20px;
  min-height: 70vh;
}

.membersList {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr 1fr)[1];
      grid-template-columns: repeat(1, 1fr 1fr);
}

.membersList .singleMember {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (40% 60%)[1];
      grid-template-columns: repeat(1, 40% 60%);
  grid-gap: 0.4em;
  padding: 20px;
}

.membersList .singleMember .leftSide .name {
  font-weight: bold;
  text-transform: capitalize;
}

.membersList .singleMember .leftSide .branch,
.membersList .singleMember .leftSide .sem {
  color: #616161;
  font-size: 0.85em;
}

.membersList .singleMember .leftSide .member {
  padding: 3px 12px;
  font-size: 0.8em;
  background-color: teal;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  width: 150px;
  margin-top: 0.4em;
}

.membersList .singleMember .rightSide .skills,
.membersList .singleMember .rightSide .interests,
.membersList .singleMember .rightSide .workshops {
  font-size: 0.85em;
}

.membersList .singleMember .rightSide .skills span,
.membersList .singleMember .rightSide .interests span,
.membersList .singleMember .rightSide .workshops span {
  font-weight: bold;
}

.membersList .singleMember .rightSide .interests,
.membersList .singleMember .rightSide .workshops {
  margin-top: 0.5em;
}

.membersList .singleMember:nth-child(4n + 1), .membersList .singleMember:nth-child(4n + 4) {
  background-color: #f2f2f2;
}

.membersList .singleMember:hover {
  background-color: #ececec;
}

@media only screen and (max-width: 992px) {
  .members-container {
    margin: 0;
  }
  .membersList {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .membersList .singleMember:nth-child(4n + 1), .membersList .singleMember:nth-child(4n + 4) {
    background-color: #fff;
  }
  .membersList .singleMember:nth-child(even) {
    background-color: #f2f2f2;
  }
}
/*# sourceMappingURL=MembersList.css.map */
.cardCont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
}
.singleCard {
  transition: 0.3s all ease;
}
.singleCard:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}
.cardImage {
  width: 100%;
  height: 270px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.cardBody {
  border: 1px solid #c0c0c0 !important;
  border-top: 0;
  padding: 15px 15px 0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  min-height: 200px;
}
.cardBody h3 {
  font-family: "Barlow Condensed", sans-serif;
}
.cardBody p {
  text-align: justify;
  font-size: 0.95em;
}

@media only screen and (max-width: 992px) {
  .cardCont {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media only screen and (max-width: 768px) {
  .cardCont {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .cardBody {
    min-height: 0;
  }
}

.gallery-container {
  position: relative;
  margin: 20px;
  min-height: 70vh;
}

.title {
  font-family: "Barlow Condensed", sans-serif;
  margin-top: 1em;
}
.title h2 {
  position: relative;
  font-size: 2em;
}
.title h2::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 50%;
  background-color: #ff7e5f;
  bottom: -0.1em;
  left: 0;
}
.aboutText {
  text-align: justify;
}
.gallery {
  margin-top: 1em;
}
.gallery img {
  border-radius: 7px;
  height: 230px;
}

footer {
  background-color: #191d34;
  color: #fff;
  padding: 50px 20px 25px;
  font-size: 16px;
}
.firstColumn {
  text-align: justify;
}
.firstColumn p {
  font-size: 0.9em;
  margin-top: 1em;
}
.whiteLogo {
  height: 90px;
}
.heading {
  border-left: 3.5px solid #fff;
  padding-left: 10px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: normal;
}
.list {
  list-style-type: none;
  margin: 30px 0 0 14px;
}
.list li {
  display: grid;
  grid-template-columns: 1fr 20fr;
  align-items: center;
  grid-column-gap: 1em;
  color: #fff;
  font-size: 0.9em;
  transition: 0.3s all ease, 0s color ease;
}
.list li:hover {
  margin-left: 10px;
  font-weight: 500;
}
.list li:not(:first-child) {
  margin-top: 10px;
}
.list li a {
  color: #fff;
  text-decoration: none;
}
.socialIcons {
  display: flex;
  justify-content: center;
}
.socialIcon:not(:first-child) {
  margin-left: 1.2em;
}
.socialIcon a {
  color: #fff;
}
.socialIcon a:hover {
  color: #ff7e5f;
}
.socialIcon i {
  font-size: 1.4em;
}
.developedBy {
  display: flex;
  justify-content: center;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.1em;
  margin-top: 1em;
}
.developedBy a {
  margin-left: 0.3em;
  color: #ff7e5f;
}
.developedBy a:hover {
  color: #ff7e5f;
  text-decoration: underline !important;
}
.developedBy a:nth-child(3) {
  margin-right: 0.3em;
}

@media only screen and (max-width: 992px) {
  footer {
    padding-top: 0;
  }
  .row > div {
    margin-top: 2.5em;
  }
}

.profileCont .profileDetails .profileHeader {
  background-color: #ebf6fa;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  grid-column-gap: 1em;
}
.profileCont .profileDetails .profileHeader .profileImage {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}
.profileCont .profileDetails .profileHeader .profileName h6 {
  color: #535353;
}
.profileCont .profileDetails .profileBody {
  margin-top: 2em;
  padding-bottom: 0.5em;
}
.profileCont .profileDetails .profileBody .profileNav {
  display: flex;
  border-bottom: 1px solid #8a8a8a;
  padding: 0 2em 0.3em;
}
.profileCont .profileDetails .profileBody .profileNav .profileNavItem {
  position: relative;
  text-align: center;
  cursor: pointer;
}
.profileCont .profileDetails .profileBody .profileNav .profileNavItem a {
  color: #8a8a8a;
  text-decoration: none;
}
.profileCont .profileDetails .profileBody .profileNav .profileNavItem a:hover {
  color: #000;
}
.profileCont .profileDetails .profileBody .profileNav .profileNavItem.active a {
  color: #000;
}
.profileCont .profileDetails .profileBody .profileNav .profileNavItem.active a::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 120%;
  background-color: #007bff;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -0.4em;
  z-index: 2;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.profileCont .profileDetails .profileBody .profileNav .profileNavItem:not(:first-child) {
  margin-left: 1em;
}
.profileCont .profileDetails .profileBody .profileNav .profileNavItem i {
  margin-right: 0.3em;
}
.profileCont .profileDetails .profileBody .profileContent {
  display: flex;
  margin: 0 5em;
  padding: 1.5em 2em 0;
}
.profileCont .profileDetails .profileBody .profileContent > div {
  margin-bottom: 1.5em;
}
.profileCont .profileDetails .profileBody .profileContent .contentHeading {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400 !important;
  font-size: 1.5em;
  color: #8a8a8a;
}
.profileCont .profileDetails .profileBody .profileContent .input-group {
  width: 80%;
}
.profileCont .profileDetails .profileBody .profileContent .infoText {
  margin-top: 1em;
  margin-right: 1em;
}
.profileCont .profileDetails .profileBody .profileContent .payment {
  border: 5px solid gray;
  border-radius: 5px;
  margin: auto;
}
.profileCont .profileDetails .profileBody .profileContent .payment a img {
  width: 100%;
  height: auto;
}
.profileCont .profileDetails .profileBody .profileContent .payment-mobile {
  display: none;
  border: 5px solid gray;
  border-radius: 5px;
  margin: 2em 0 auto;
}
.profileCont .profileDetails .profileBody .profileContent .payment-mobile a img {
  width: 100%;
  height: auto;
}
.profileCont .profileDetails .profileBody .dashboard {
  margin-top: 2em;
  text-align: center;
}
.profileCont .profileDetails .profileBody .dashboard .introMessage p {
  color: #818181;
}
.profileCont .profileDetails .profileBody .dashboard .statCardsCont {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5em;
  margin: 2em 1.5em;
}
.profileCont .profileDetails .profileBody .dashboard .statCardsCont .statCard {
  background-color: #fff;
  box-shadow: 6px 6px 15px #d6d6d6, -6px -6px 15px #ffffff;
  border-radius: 7px;
  display: grid;
  grid-template-columns: repeat(1, 50% 50%);
  color: #000;
  transition: 0.3s all ease;
}
.profileCont .profileDetails .profileBody .dashboard .statCardsCont .statCard:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}
.profileCont .profileDetails .profileBody .dashboard .statCardsCont .statCard .cardDetails {
  width: 100%;
  padding: 15px 10px;
}
.profileCont .profileDetails .profileBody .dashboard .statCardsCont .statCard .cardDetails h5 {
  text-align: left;
}
.profileCont .profileDetails .profileBody .dashboard .statCardsCont .statCard .cardDetails .cardCount {
  font-size: 2em;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: bold;
  font-size: 3.5em;
  color: #191d34;
  text-align: center;
}
.profileCont .profileDetails .profileBody .dashboard .statCardsCont .statCard img {
  height: 160px;
  width: 100%;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.displaySuperCoins {
  max-width: -webkit-max-content;
  max-width: max-content;
  background: #191d34;
  color: #fff !important;
  padding: 0.5em 1em;
  border-radius: 10px;
  /* box-shadow: 3px 3px 3px black; */
}

@media only screen and (max-width: 992px) {
  .profileCont .profileDetails .profileHeader {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .profileCont .profileDetails .profileHeader .profileImage {
    margin-bottom: 1em;
  }
  .profileCont .profileBody .profileContent {
    flex-direction: column;
    margin: 0 !important;
  }
  .profileCont .profileBody .profileContent .payment {
    display: none;
  }
  .profileCont .profileBody .profileContent .infoText {
    margin-top: 1em;
    margin-right: 0 !important;
  }
  .profileCont .profileBody .profileContent .payment-mobile {
    display: block !important;
  }
  .profileCont .profileBody .profileContent .input-group {
    width: 100% !important;
  }
  .profileCont .profileBody .dashboard .statCardsCont {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media only screen and (max-width: 768px) {
  .profileCont .profileBody .dashboard .statCardsCont {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
  }
}/*# sourceMappingURL=ProfileComponents.css.map */
.signUpCont {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr 2fr)[1];
      grid-template-columns: repeat(1, 1fr 2fr);
}

.signUpCont .image {
  background-image: url(/static/media/background.d4611e2f.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 90vh;
}

.signUpCont .form {
  -ms-grid-row-align: start;
  align-self: start;
  width: 75%;
  margin: auto;
}

.signUpCont .form .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 0 0 1em;
}

.signUpCont .form .inputGroup {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5em;
}

.signUpCont .form .inputGroup.twoInputs {
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.signUpCont .form .inputGroup.threeInputs {
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
}

.signUpCont .form .inputGroup.w50p {
  width: 50%;
  margin: auto;
}

.signUpCont .form .inputGroup .input {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.signUpCont .form .inputGroup .input input,
.signUpCont .form .inputGroup .input select,
.signUpCont .form .inputGroup .input textarea {
  padding: 10px 20px;
  color: #191d34;
  border: 2px solid #191d34;
  border-radius: 5px;
  width: 100%;
  outline: none;
}

.signUpCont .form .inputGroup .input input::-webkit-input-placeholder,
.signUpCont .form .inputGroup .input select::-webkit-input-placeholder,
.signUpCont .form .inputGroup .input textarea::-webkit-input-placeholder {
  opacity: 0;
}

.signUpCont .form .inputGroup .input input:-ms-input-placeholder,
.signUpCont .form .inputGroup .input select:-ms-input-placeholder,
.signUpCont .form .inputGroup .input textarea:-ms-input-placeholder {
  opacity: 0;
}

.signUpCont .form .inputGroup .input input::-webkit-input-placeholder, .signUpCont .form .inputGroup .input select::-webkit-input-placeholder, .signUpCont .form .inputGroup .input textarea::-webkit-input-placeholder {
  opacity: 0;
}

.signUpCont .form .inputGroup .input input:-ms-input-placeholder, .signUpCont .form .inputGroup .input select:-ms-input-placeholder, .signUpCont .form .inputGroup .input textarea:-ms-input-placeholder {
  opacity: 0;
}

.signUpCont .form .inputGroup .input input::placeholder,
.signUpCont .form .inputGroup .input select::placeholder,
.signUpCont .form .inputGroup .input textarea::placeholder {
  opacity: 0;
}

.signUpCont .form .inputGroup .input input:not(:-ms-input-placeholder), .signUpCont .form .inputGroup .input select:not(:-ms-input-placeholder), .signUpCont .form .inputGroup .input textarea:not(:-ms-input-placeholder) {
  border-color: #191d34;
}

.signUpCont .form .inputGroup .input input:focus, .signUpCont .form .inputGroup .input input:not(:placeholder-shown),
.signUpCont .form .inputGroup .input select:focus,
.signUpCont .form .inputGroup .input select:not(:placeholder-shown),
.signUpCont .form .inputGroup .input textarea:focus,
.signUpCont .form .inputGroup .input textarea:not(:placeholder-shown) {
  border-color: #191d34;
}

.signUpCont .form .inputGroup .input input:not(:-ms-input-placeholder) ~ label, .signUpCont .form .inputGroup .input select:not(:-ms-input-placeholder) ~ label, .signUpCont .form .inputGroup .input textarea:not(:-ms-input-placeholder) ~ label {
  top: 0;
  left: 7px;
  font-size: 0.9rem;
}

.signUpCont .form .inputGroup .input input:focus ~ label, .signUpCont .form .inputGroup .input input:not(:placeholder-shown) ~ label,
.signUpCont .form .inputGroup .input select:focus ~ label,
.signUpCont .form .inputGroup .input select:not(:placeholder-shown) ~ label,
.signUpCont .form .inputGroup .input textarea:focus ~ label,
.signUpCont .form .inputGroup .input textarea:not(:placeholder-shown) ~ label {
  top: 0;
  left: 7px;
  font-size: 0.9rem;
}

.signUpCont .form .inputGroup .input label {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #fff;
  padding: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s all ease;
}

.signUpCont .form .inputGroup:not(:first-child) {
  margin-top: 1.5em;
}

.signUpCont .form .inputGroup button {
  padding: 7px 45px;
  outline: none;
}

.signUpCont .form .inputGroup button.google {
  background-color: #dc3545;
  border-color: #dc3545;
}

.signUpCont .form .link {
  margin-top: 1em;
}

@media only screen and (max-width: 900px) {
  .signUpCont {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    padding: 5vh 15px;
  }
  .signUpCont .image {
    display: none;
  }
  .signUpCont .form {
    width: 100%;
  }
  .signUpCont .form .title {
    font-size: 1.7rem;
  }
  .signUpCont .form .inputGroup.twoInputs {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .signUpCont .form .inputGroup.threeInputs {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .signUpCont .form .inputGroup.w50p {
    width: 100%;
  }
  .signUpCont .form .inputGroup button {
    width: 100%;
  }
  .signUpCont .form .link {
    margin-top: 1em;
  }
}
/*# sourceMappingURL=SignUpComponent.css.map */
.login-container {
  margin: 20px;
  position: relative;
  min-height: 70vh;
}

.login-card {
  margin: 20px auto;
  padding: 10px;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  max-width: 600px;
  /* width: 40%; */
}

hr.solid {
  border-top: 3px solid #bbb;
}

.login-card-title {
  font-size: 28px;
  text-align: center;
  font-weight: 700;
}

.login-card-subtitle {
  font-size: 24px;
  text-align: center;
  font-weight: 550;
}

.margin-top-20{
  margin-top: 20px;
  text-align: right;
}

.login-form {
  padding: 25px;
  text-align: center;
}


@media only screen and (max-width: 900px) {
  .signUpCont .form .signupforgot .forgot {
    margin-top:0px;
    text-align: start;
  }
}
.forgot-password-container {
  margin: 20px;
  position: relative;
  min-height: 70vh;
}

.forgot-password-card {
  margin: 20px auto;
  padding: 10px;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  max-width: 600px;
  /* width: 40%; */
}

hr.solid {
  border-top: 3px solid #bbb;
}

.forgot-password-card-title {
  font-size: 28px;
  text-align: center;
  font-weight: 700;
}

.forgot-password-card-subtitle {
  font-size: 24px;
  text-align: center;
  font-weight: 550;
}

.forgot-password-form {
  padding: 25px;
  text-align: center;
}

.reset-btn{ 
  background-color: #dc3545 !important;
  border-color: #dc3545 !important
}
.editBlogContainer {
  margin: 20px;
}

.title {
  font-family: "Barlow Condensed", sans-serif;
  margin-top: 1em;
}

.title h3 {
  position: relative;
  font-size: 2em;
}

.title h3::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 50%;
  background-color: #ff7e5f;
  bottom: -0.1em;
  left: 0;
}

.summernote {
  margin-top: 1.5em;
}

.summernote textarea {
  width: 100%;
}

.formsCont {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 1em;
}

@media only screen and (min-width: 992px) {
  .formsCont {
    -ms-grid-columns: (3fr 2fr)[1];
        grid-template-columns: repeat(1, 3fr 2fr);
  }
  .formsCont form {
    width: 90%;
    margin: 0 auto;
  }
}
/*# sourceMappingURL=EditBlogComponent.css.map */
.blogContainer {
  margin: 10px;
}

.blogContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blogContents .blogDetails {
  padding: 0 5px 15px;
  font-family: "Montserrat", sans-serif !important;
  text-align: justify;
  font-size: 0.9em;
}

.blogContents .blogDetails p span {
  font-family: "Montserrat", sans-serif !important;
  font-size: 1.05em !important;
}

.blogContents .shareButtons {
  margin-top: 1em;
  -ms-grid-row-align: start;
  align-self: start;
}

.blogContents .shareButtons button {
  outline: none;
}

.blogContents .shareButtons button:not(:first-child) {
  margin-left: 0.5em;
}

.blogContents .addComment {
  display: flex;
}

.blogContents .addComment .left img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.blogContents .addComment .right {
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.blogContents .addComment .right textarea {
  width: 75vw;
  margin-top: 0;
  font-size: 0.9rem;
  padding: 7px;
}

.blogContents .addComment .right button {
  width: -webkit-min-content;
  width: min-content;
  white-space: nowrap;
  margin: 0.5em 0 0 0;
}

.blogContents .commentsList .singleComment {
  display: flex;
}

.blogContents .commentsList .singleComment .left img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.blogContents .commentsList .singleComment .right {
  margin-left: 1em;
}

.blogContents .commentsList .singleComment .right h5 {
  font-size: 1rem;
  font-weight: 600;
}

.blogContents .commentsList .singleComment .right h5 .comment {
  font-size: 0.95rem;
  font-weight: 400;
  margin-left: 0.5em;
}

.blogContents .commentsList .singleComment .right .date {
  color: #727070;
  font-size: 0.85rem;
  margin-top: 0.5em;
}

.blogContents .commentsList .singleComment .right .date .actionBtns {
  margin-left: 1em;
}

.blogContents .commentsList .singleComment .right .date .actionBtns button {
  padding: 3px 12px;
  font-size: 0.8em;
  margin-top: 0;
}

.blogContents .commentsList .singleComment:not(:first-child) {
  position: relative;
  margin-top: 2em;
}

.blogContents .commentsList .singleComment:not(:first-child)::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #a5a1a1;
  top: -1em;
}

@media only screen and (min-width: 992px) {
  .blogContents {
    width: 70vw;
    margin: auto;
  }
  .blogContents .shareButtons {
    margin: 2em 0 0 0;
  }
  .blogContents .addComment .right textarea {
    width: 70vw;
  }
  .blogContents .addComment .right button {
    margin: 0.5em 0 0 0;
  }
}
/*# sourceMappingURL=BlogComponent.css.map */
.modalCont {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
  opacity: 0;
  z-index: 10000;
  -webkit-animation: appear 0.3s linear;
          animation: appear 0.3s linear;
  transition: 0.3s all ease;
}

.modalCont .modalContent .modalBody {
  width: 60vw;
  margin: 20vh auto 0;
  padding: 2em;
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
}

.modalCont .modalContent .modalBody img {
  height: 100px;
  margin-bottom: 3em;
}

.modalCont .modalContent .modalBody p {
  word-wrap: break-word;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 2em;
}

.modalCont .modalContent .modalBody .buttons button {
  padding: 10px 45px;
  background-color: #adb9ffbb;
  color: #191d34;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}

.modalCont.show {
  opacity: 1;
  pointer-events: all;
}

@-webkit-keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
  .modalCont .modalContent .modalBody {
    width: 90vw;
    padding: 2em 1em;
  }
  .modalCont .modalContent .modalBody img {
    height: 80px;
    margin-bottom: 2em;
  }
  .modalCont .modalContent .modalBody p {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 768px) {
  .modalCont .modalContent .modalBody .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .modalCont .modalContent .modalBody .buttons button {
    width: 200px;
  }
  .modalCont .modalContent .modalBody .buttons button:last-child {
    margin: 1em 0 0 0 !important;
  }
}
/*# sourceMappingURL=AlertModalComponent.css.map */
.collabContainer {
  margin: 0 20px 20px;
}

.collabContainer .landing {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  align-items: center;
}

.collabContainer .landing .content {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
}

.collabContainer .landing .content .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.5em;
}

.collabContainer .landing .content .text {
  font-size: 0.9em;
  color: #616161;
  margin: 7px 0 0 15px;
  text-align: justify;
}

.collabContainer .landing img {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  width: 100%;
}

.collabContainer .partners {
  margin-top: 2em;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 5em;
  justify-items: center;
  align-items: center;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: #000;
  text-align: center;
  height: 1.5em;
}

.hr-text::before {
  content: "";
  background: linear-gradient(to right, transparent, #000, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text::after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 0.5em;
  line-height: 1.5em;
  background-color: #fcfcfa;
}

@media only screen and (min-width: 992px) {
  .collabContainer .landing {
    margin: 0 5vw;
    height: 90vh;
    -ms-grid-columns: (45% 55%)[1];
        grid-template-columns: repeat(1, 45% 55%);
  }
  .collabContainer .landing .content {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
  }
  .collabContainer .landing img {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1;
  }
  .collabContainer .partners {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
}
/*# sourceMappingURL=CollaborationsComponent.css.map */
.categoriesCont {
  margin: 20px 2em 3em;
}

.categoriesCont .categoriesList {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  grid-gap: 2em;
}

.categoriesCont .categoriesList .singleCategory {
  color: #000;
  text-align: center;
  border-radius: 10px;
  box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
  transition: 0.3s all ease;
}

.categoriesCont .categoriesList .singleCategory .categoryImage {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.categoriesCont .categoriesList .singleCategory .categoryContent {
  padding: 20px 10px;
}

.categoriesCont .categoriesList .singleCategory .categoryContent .categoryTitle {
  font-weight: 600;
  font-size: 1.5em;
}

.categoriesCont .categoriesList .singleCategory:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

@media only screen and (max-width: 992px) {
  .categoriesCont .categoriesList {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .categoriesCont .categoriesList {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
}
/*# sourceMappingURL=CategoriesComponent.css.map */
.blogContainer {
  margin: 20px;
}

.blogContents {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5em;
}

.blogContents .singleBlog {
  display: flex;
  flex-direction: column;
  color: #000;
  text-decoration: none;
  box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
  border-radius: 10px;
  transition: 0.3s all ease;
}

.blogContents .singleBlog .blogImage {
  height: 220px;
  border-radius: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.blogContents .singleBlog .blogHeader {
  width: 100%;
  padding: 20px;
}

.blogContents .singleBlog .blogHeader .headerContent .blogTitle {
  font-size: 1.5em;
}

.blogContents .singleBlog .blogHeader .headerContent .blogAuthor {
  margin-top: 0.5em;
  font-size: 1em;
}

.blogContents .singleBlog .blogHeader .headerContent .blogCategory {
  color: #616161;
  font-size: 0.9em;
}

.blogContents .singleBlog .blogHeader .headerContent .blogDate {
  font-size: 0.9em;
  margin-top: 1em;
}

.blogContents .singleBlog:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.alertMessage {
  text-align: center;
}

.alertMessage h4 {
  margin: 1em 0;
  font-size: 2em;
}

@media only screen and (min-width: 992px) {
  .blogContents {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5em;
    padding: 0;
  }
  .blogContents .singleBlog {
    display: flex;
    flex-direction: row;
  }
  .blogContents .singleBlog .blogImage {
    height: 220px;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/*# sourceMappingURL=BlogListComponent.css.map */
.h3-text {
  text-align: center;
}

.admin-container {
  position: relative;
  margin: 20px;
  min-height: 70vh;
}

.membersList {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr 1fr)[1];
      grid-template-columns: repeat(1, 1fr 1fr);
}

.membersList .singleMember {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (40% 60%)[1];
      grid-template-columns: repeat(1, 40% 60%);
  grid-gap: 0.4em;
  padding: 20px;
}

.membersList .singleMember .leftSide .name {
  font-weight: 600;
  text-transform: capitalize;
}

.membersList .singleMember .leftSide .branch,
.membersList .singleMember .leftSide .sem {
  color: #616161;
  font-size: 0.85em;
}

.membersList .singleMember .leftSide .member,
.membersList .singleMember .leftSide .payment {
  padding: 3px 12px;
  font-size: 0.8em;
  background-color: teal;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  width: 150px;
  margin-top: 0.4em;
}

.membersList .singleMember .leftSide .payment.green {
  background-color: #3cb371;
}

.membersList .singleMember .leftSide .payment.red {
  background-color: #f23d5d;
}

.membersList .singleMember .rightSide .email,
.membersList .singleMember .rightSide .contactno {
  font-size: 0.85em;
}

.membersList .singleMember .rightSide .email span,
.membersList .singleMember .rightSide .contactno span {
  font-weight: bold;
}

.membersList .singleMember .rightSide .interests,
.membersList .singleMember .rightSide .workshops {
  margin-top: 0.5em;
}

.membersList .singleMember .rightSide .actionBtns button {
  padding: 3px 12px;
  font-size: 0.8em;
}

.membersList .singleMember:nth-child(4n + 1), .membersList .singleMember:nth-child(4n + 4) {
  background-color: #f2f2f2;
}

.membersList .singleMember:hover {
  background-color: #ececec;
}

/* Popup Menus */
.popupMenus {
  position: fixed;
  pointer-events: none;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  opacity: 0;
  transition: 0.4s all ease;
}

.popupMenus > div {
  position: relative;
  display: none;
  margin: 40vh 5vw;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #fff;
  padding: 0 0 15px;
  border-radius: 10px;
}

.popupMenus > div .closePopup {
  position: absolute;
  color: #fff;
  background-color: #191d34;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 15px;
  top: -15px;
  right: -15px;
  border: 3px solid #fff;
  text-align: center;
  cursor: pointer;
}

.popupMenus > div .closePopup i {
  -webkit-transform: translate(-50%, -75%);
          transform: translate(-50%, -75%);
}

.popupMenus > div .popupHeading {
  background-color: #191d34;
  font-size: 1.3em;
  color: #fff;
  padding: 10px;
  width: 100%;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.popupMenus > div .popupBody {
  margin: 0 15px;
  padding: 15px 0 0;
}

.popupMenus > div .popupBody .profileDetails {
  font-size: 0.9em;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr 1fr)[1];
      grid-template-columns: repeat(1, 1fr 1fr);
  grid-gap: 0.3em;
}

.popupMenus.popupVisible {
  opacity: 1;
  pointer-events: all;
}

/* End Popup Menus */
/* Responsive mode for Desktop */
@media only screen and (min-width: 992px) {
  .popupMenus > div {
    margin: 20vh 33vw;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

/* End responsive mode */
@media only screen and (max-width: 992px) {
  .admin-container {
    margin: 0;
  }
  .membersList {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .membersList .singleMember:nth-child(4n + 1), .membersList .singleMember:nth-child(4n + 4) {
    background-color: #fff;
  }
  .membersList .singleMember:nth-child(even) {
    background-color: #f2f2f2;
  }
  .popupMenus > div .popupBody .profileDetails {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }
}
/*# sourceMappingURL=NewMembersComponent.css.map */
.addEventForm {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 1em;
  padding: 2vh 20px;
}

@media only screen and (min-width: 992px) {
  .addEventForm {
    width: 75vw;
    margin: 2vh auto;
  }
  .addEventForm form {
    width: 100%;
  }
}
/*# sourceMappingURL=AddEventComponent.css.map */
.sectionTitle {
  text-align: center;
  margin: 2em 0 1em;
  font-size: 2em;
}

a {
  text-decoration: none !important;
}

.eventsCardCont {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5em;
  margin-bottom: 3em;
}

.eventsCardCont .singleEvent {
  display: flex;
  flex-direction: column;
  color: #000;
  text-decoration: none;
  box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
  border-radius: 10px;
  transition: 0.3s all ease;
}

.eventsCardCont .singleEvent .eventImage {
  width: 100%;
  border-radius: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.eventsCardCont .singleEvent .eventHeader {
  width: 100%;
  padding: 20px;
}

.eventsCardCont .singleEvent .eventHeader .headerContent .eventTitle {
  font-size: 1.5em;
}

.eventsCardCont .singleEvent .eventHeader .headerContent .eventDate {
  font-size: 0.9em;
  color: #727171;
}

.eventsCardCont .singleEvent:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.comingSoon {
  background-color: darkgray;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  height: 25vh;
}

.comingSoon h2 {
  font-size: 1.5rem;
}

.comingSoon h5 {
  font-size: 1.1rem;
}

.alertMessage {
  text-align: center;
}

.alertMessage h4 {
  margin: 1em 0;
  font-size: 2em;
}

@media only screen and (min-width: 992px) {
  .eventsCardCont {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5em;
    padding: 0;
  }
  .eventsCardCont .singleEvent {
    display: flex;
    flex-direction: row;
  }
  .eventsCardCont .singleEvent .eventImage {
    height: 220px;
    width: auto;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .comingSoon h2 {
    font-size: 2rem;
  }
  .comingSoon h5 {
    font-size: 1.5rem;
  }
}
/*# sourceMappingURL=EventsComponent.css.map */
.singleEventCont {
  padding: 30px 15vw;
}

.singleEventCont .eventDetails {
  padding: 0 15px 15px;
  font-family: "Montserrat", sans-serif !important;
  text-align: justify;
  font-size: 0.9em;
}

.singleEventCont .eventDetails p span {
  font-family: "Montserrat", sans-serif !important;
  font-size: 1.05em !important;
}

.singleEventCont .shareButtons {
  margin-top: 1em;
  -ms-grid-row-align: start;
  align-self: start;
}

.singleEventCont .shareButtons button {
  outline: none;
}

.singleEventCont .shareButtons button:not(:first-child) {
  margin-left: 0.5em;
}

.singleEventCont .editBtn button {
  margin: 20px 0;
  padding: 7px 30px;
  background-color: #191d34;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  outline: none;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .singleEventCont {
    padding: 30px 20px;
  }
}
/*# sourceMappingURL=SingleEventComponent.css.map */
.achievementCard {
  width: 100%;
  box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
  border-radius: 10px;
  margin: 0 auto 1.5em;
  transition: 0.3s all ease;
}
.achievementCard:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}
.achievementImage {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: 0.3s all ease;
}
.achievementDetails {
  color: #000;
  text-decoration: none;
  text-align: center;
  font-size: 0.95em;
  padding: 20px 20px 7px;
}
.achievementDetails::-webkit-scrollbar {
  width: 3px;
}
.achievementDetails::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.achievementDetails::-webkit-scrollbar-thumb {
  background-color: #000;
}
.achievementDetails p {
  text-align: justify;
}

@media only screen and (min-width: 768px) {
  .achievementImage {
    height: 270px;
  }
  .achievementDetails {
    height: 200px;
    overflow: auto;
  }
}

@media only screen and (min-width: 992px) {
  .achievementImage {
    height: 300px;
  }
}

.achievementsCont {
  margin: 20px;
}

.createTestCont .title {
  text-align: center;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15vw;
  text-align: left;
  font-size: 2rem;
}

.createTestCont .title button {
  font-size: 1rem;
  padding: 10px 30px;
  background-color: #33b5e5;
  border: 2px solid #33b5e5;
  color: #fff;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.createTestCont .title button:hover {
  background-color: #0099cc;
  border-color: #0099cc;
}

.createTestCont .centreCard {
  background-color: #fff;
  box-shadow: 18px 18px 36px #bebebe, -18px -18px 36px #ffffff;
  margin: 3em 15vw;
  border-radius: 20px;
}

.createTestCont .centreCard .createForm {
  padding: 10px 30px 30px;
}

.createTestCont .centreCard .createForm form .smallTitle {
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1.5em;
}

.createTestCont .centreCard .createForm form .inputGroup {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5em;
}

.createTestCont .centreCard .createForm form .inputGroup.twoInputs {
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.createTestCont .centreCard .createForm form .inputGroup.threeInputs {
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
}

.createTestCont .centreCard .createForm form .inputGroup.w50p {
  width: 50%;
  margin: auto;
}

.createTestCont .centreCard .createForm form .inputGroup .input {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.createTestCont .centreCard .createForm form .inputGroup .input input,
.createTestCont .centreCard .createForm form .inputGroup .input select,
.createTestCont .centreCard .createForm form .inputGroup .input textarea {
  padding: 10px 20px;
  color: #191d34;
  border: 2px solid #191d34;
  border-radius: 5px;
  width: 100%;
  outline: none;
}

.createTestCont .centreCard .createForm form .inputGroup .input input::-webkit-input-placeholder,
.createTestCont .centreCard .createForm form .inputGroup .input select::-webkit-input-placeholder,
.createTestCont .centreCard .createForm form .inputGroup .input textarea::-webkit-input-placeholder {
  opacity: 0;
}

.createTestCont .centreCard .createForm form .inputGroup .input input:-ms-input-placeholder,
.createTestCont .centreCard .createForm form .inputGroup .input select:-ms-input-placeholder,
.createTestCont .centreCard .createForm form .inputGroup .input textarea:-ms-input-placeholder {
  opacity: 0;
}

.createTestCont .centreCard .createForm form .inputGroup .input input::-webkit-input-placeholder, .createTestCont .centreCard .createForm form .inputGroup .input select::-webkit-input-placeholder, .createTestCont .centreCard .createForm form .inputGroup .input textarea::-webkit-input-placeholder {
  opacity: 0;
}

.createTestCont .centreCard .createForm form .inputGroup .input input:-ms-input-placeholder, .createTestCont .centreCard .createForm form .inputGroup .input select:-ms-input-placeholder, .createTestCont .centreCard .createForm form .inputGroup .input textarea:-ms-input-placeholder {
  opacity: 0;
}

.createTestCont .centreCard .createForm form .inputGroup .input input::placeholder,
.createTestCont .centreCard .createForm form .inputGroup .input select::placeholder,
.createTestCont .centreCard .createForm form .inputGroup .input textarea::placeholder {
  opacity: 0;
}

.createTestCont .centreCard .createForm form .inputGroup .input input:not(:-ms-input-placeholder), .createTestCont .centreCard .createForm form .inputGroup .input select:not(:-ms-input-placeholder), .createTestCont .centreCard .createForm form .inputGroup .input textarea:not(:-ms-input-placeholder) {
  border-color: #191d34;
}

.createTestCont .centreCard .createForm form .inputGroup .input input:focus, .createTestCont .centreCard .createForm form .inputGroup .input input:not(:placeholder-shown),
.createTestCont .centreCard .createForm form .inputGroup .input select:focus,
.createTestCont .centreCard .createForm form .inputGroup .input select:not(:placeholder-shown),
.createTestCont .centreCard .createForm form .inputGroup .input textarea:focus,
.createTestCont .centreCard .createForm form .inputGroup .input textarea:not(:placeholder-shown) {
  border-color: #191d34;
}

.createTestCont .centreCard .createForm form .inputGroup .input input:not(:-ms-input-placeholder) ~ label, .createTestCont .centreCard .createForm form .inputGroup .input select:not(:-ms-input-placeholder) ~ label, .createTestCont .centreCard .createForm form .inputGroup .input textarea:not(:-ms-input-placeholder) ~ label {
  top: 0;
  left: 7px;
  font-size: 0.9rem;
}

.createTestCont .centreCard .createForm form .inputGroup .input input:focus ~ label, .createTestCont .centreCard .createForm form .inputGroup .input input:not(:placeholder-shown) ~ label,
.createTestCont .centreCard .createForm form .inputGroup .input select:focus ~ label,
.createTestCont .centreCard .createForm form .inputGroup .input select:not(:placeholder-shown) ~ label,
.createTestCont .centreCard .createForm form .inputGroup .input textarea:focus ~ label,
.createTestCont .centreCard .createForm form .inputGroup .input textarea:not(:placeholder-shown) ~ label {
  top: 0;
  left: 7px;
  font-size: 0.9rem;
}

.createTestCont .centreCard .createForm form .inputGroup .input label {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #fff;
  padding: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s all ease;
}

.createTestCont .centreCard .createForm form .inputGroup:not(:first-child) {
  margin-top: 1.5em;
}

.createTestCont .centreCard .createForm form:not(:first-child) {
  margin-top: 1.5em;
}

.createTestCont .centreCard .createForm button {
  border-radius: 5px;
  padding: 10px 30px;
  color: #fff;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.createTestCont .centreCard .createForm button.addBtn {
  background-color: #33b5e5;
  border: 2px solid #33b5e5;
}

.createTestCont .centreCard .createForm button.addBtn:hover {
  background-color: #0099cc;
  border: 2px solid #0099cc;
}

.createTestCont .centreCard .createForm button.removeBtn {
  height: 30px;
  width: 30px;
  padding: 0;
  background-color: #eb0808;
  border: 2px solid #eb0808;
  font-size: 0.9rem;
}

.createTestCont .centreCard .createForm button.removeBtn:hover {
  background-color: #ce0909;
  border: 2px solid #ce0909;
}

.createTestCont .centreCard .createForm button.createBtn {
  background-color: #00c851;
  border: 2px solid #00c851;
}

.createTestCont .centreCard .createForm button.createBtn:hover {
  background-color: #007e33;
  border: 2px solid #007e33;
}

@media only screen and (max-width: 992px) {
  .createTestCont .title {
    padding: 0 20px;
    font-size: 1.7rem;
  }
  .createTestCont .title button {
    padding: 7px 10px;
    font-size: 0.9rem;
  }
  .createTestCont .centreCard {
    margin: 2em 20px 3em;
    border-radius: 10px;
  }
  .createTestCont .centreCard .createForm {
    padding: 10px 20px 30px;
  }
  .createTestCont .centreCard .createForm form .inputGroup.twoInputs {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .createTestCont .centreCard .createForm form .inputGroup.threeInputs {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .createTestCont .centreCard .createForm form .inputGroup.w50p {
    width: 100%;
  }
  .createTestCont .centreCard .createForm form .inputGroup .input input,
  .createTestCont .centreCard .createForm form .inputGroup .input select,
  .createTestCont .centreCard .createForm form .inputGroup .input textarea {
    padding: 7px 15px;
    border: 2px solid #191d34;
    border-radius: 5px;
    outline: none;
  }
  .createTestCont .centreCard .createForm form .inputGroup .input input::-webkit-input-placeholder,
  .createTestCont .centreCard .createForm form .inputGroup .input select::-webkit-input-placeholder,
  .createTestCont .centreCard .createForm form .inputGroup .input textarea::-webkit-input-placeholder {
    opacity: 0;
  }
  .createTestCont .centreCard .createForm form .inputGroup .input input:-ms-input-placeholder,
  .createTestCont .centreCard .createForm form .inputGroup .input select:-ms-input-placeholder,
  .createTestCont .centreCard .createForm form .inputGroup .input textarea:-ms-input-placeholder {
    opacity: 0;
  }
  .createTestCont .centreCard .createForm form .inputGroup .input input::-webkit-input-placeholder, .createTestCont .centreCard .createForm form .inputGroup .input select::-webkit-input-placeholder, .createTestCont .centreCard .createForm form .inputGroup .input textarea::-webkit-input-placeholder {
    opacity: 0;
  }
  .createTestCont .centreCard .createForm form .inputGroup .input input:-ms-input-placeholder, .createTestCont .centreCard .createForm form .inputGroup .input select:-ms-input-placeholder, .createTestCont .centreCard .createForm form .inputGroup .input textarea:-ms-input-placeholder {
    opacity: 0;
  }
  .createTestCont .centreCard .createForm form .inputGroup .input input::placeholder,
  .createTestCont .centreCard .createForm form .inputGroup .input select::placeholder,
  .createTestCont .centreCard .createForm form .inputGroup .input textarea::placeholder {
    opacity: 0;
  }
  .createTestCont .centreCard .createForm form .inputGroup .input input:not(:-ms-input-placeholder) ~ label, .createTestCont .centreCard .createForm form .inputGroup .input select:not(:-ms-input-placeholder) ~ label, .createTestCont .centreCard .createForm form .inputGroup .input textarea:not(:-ms-input-placeholder) ~ label {
    font-size: 0.75rem;
  }
  .createTestCont .centreCard .createForm form .inputGroup .input input:focus ~ label, .createTestCont .centreCard .createForm form .inputGroup .input input:not(:placeholder-shown) ~ label,
  .createTestCont .centreCard .createForm form .inputGroup .input select:focus ~ label,
  .createTestCont .centreCard .createForm form .inputGroup .input select:not(:placeholder-shown) ~ label,
  .createTestCont .centreCard .createForm form .inputGroup .input textarea:focus ~ label,
  .createTestCont .centreCard .createForm form .inputGroup .input textarea:not(:placeholder-shown) ~ label {
    font-size: 0.75rem;
  }
  .createTestCont .centreCard .createForm form .inputGroup .input label {
    left: 15px;
    font-size: 0.9rem;
  }
  .createTestCont .centreCard .createForm button {
    padding: 7px 15px;
  }
}
/*# sourceMappingURL=CreateTestComponent.css.map */
.testsListCont .title {
  text-align: center;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15vw;
  text-align: left;
  font-size: 2rem;
}

.testsListCont .title button {
  font-size: 1rem;
  padding: 10px 30px;
  background-color: #00c851;
  border: 2px solid #00c851;
  color: #fff;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.testsListCont .title button:hover {
  background-color: #007e33;
  border-color: #007e33;
}

.testsListCont .centreCard {
  background-color: #fff;
  box-shadow: 18px 18px 36px #bebebe, -18px -18px 36px #ffffff;
  margin: 3em 15vw;
  border-radius: 20px;
}

.testsListCont .centreCard .testsList {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.testsListCont .centreCard .testsList .test {
  padding: 20px 50px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr 3fr 1fr 2fr)[1];
      grid-template-columns: repeat(1, 1fr 3fr 1fr 2fr);
  justify-items: center;
  align-items: center;
}

.testsListCont .centreCard .testsList .test > div {
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.testsListCont .centreCard .testsList .test > div strong {
  font-weight: 500;
}

.testsListCont .centreCard .testsList .test .onlyMobile {
  display: none;
}

.testsListCont .centreCard .testsList .test .index {
  text-align: left;
}

.testsListCont .centreCard .testsList .test .testTitle {
  text-align: left;
  font-weight: 500;
  overflow: hidden;
}

.testsListCont .centreCard .testsList .test .testTitle a {
  text-decoration: underline !important;
  text-overflow: ellipsis;
}

.testsListCont .centreCard .testsList .test .testTitle .date {
  font-size: 0.9rem;
  font-weight: 400;
}

.testsListCont .centreCard .testsList .test .buttons {
  text-align: right;
}

.testsListCont .centreCard .testsList .test .buttons a button {
  background: transparent;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.testsListCont .centreCard .testsList .test .buttons a button i {
  transition: 0.3s all ease;
}

.testsListCont .centreCard .testsList .test .buttons a:first-child button {
  border: 1px solid #00c851;
}

.testsListCont .centreCard .testsList .test .buttons a:first-child button i {
  color: #00c851;
}

.testsListCont .centreCard .testsList .test .buttons a:first-child:hover button {
  background-color: #00c851;
}

.testsListCont .centreCard .testsList .test .buttons a:first-child:hover button i {
  color: #fff;
}

.testsListCont .centreCard .testsList .test .buttons a:nth-child(2) {
  margin-left: 1em;
}

.testsListCont .centreCard .testsList .test .buttons a:nth-child(2) button {
  border: 1px solid #33b5e5;
}

.testsListCont .centreCard .testsList .test .buttons a:nth-child(2) button i {
  color: #33b5e5;
}

.testsListCont .centreCard .testsList .test .buttons a:nth-child(2):hover button {
  background-color: #33b5e5;
}

.testsListCont .centreCard .testsList .test .buttons a:nth-child(2):hover button i {
  color: #fff;
}

.testsListCont .centreCard .testsList .test .buttons a:nth-child(3) {
  margin-left: 1em;
}

.testsListCont .centreCard .testsList .test .buttons a:nth-child(3) button {
  border: 1px solid #ff4444;
}

.testsListCont .centreCard .testsList .test .buttons a:nth-child(3) button i {
  color: #ff4444;
}

.testsListCont .centreCard .testsList .test .buttons a:nth-child(3):hover button {
  background-color: #ff4444;
}

.testsListCont .centreCard .testsList .test .buttons a:nth-child(3):hover button i {
  color: #fff;
}

.testsListCont .centreCard .testsList .test .buttons a:last-child {
  margin-left: 1em;
}

.testsListCont .centreCard .testsList .test .buttons a:last-child button {
  border: 1px solid #ffbb33;
}

.testsListCont .centreCard .testsList .test .buttons a:last-child button i {
  color: #ffbb33;
}

.testsListCont .centreCard .testsList .test .buttons a:last-child:hover button {
  background-color: #ffbb33;
}

.testsListCont .centreCard .testsList .test .buttons a:last-child:hover button i {
  color: #fff;
}

.testsListCont .centreCard .testsList .test:nth-child(odd) {
  background-color: #f2f2f2;
}

.testsListCont .centreCard .testsList .test:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.testsListCont .centreCard .testsList .test:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

@media only screen and (max-width: 992px) {
  .testsListCont .title {
    padding: 0 20px;
    font-size: 1.7rem;
  }
  .testsListCont .title button {
    padding: 7px 10px;
    font-size: 0.9rem;
  }
  .testsListCont .centreCard {
    margin: 2em 20px 3em;
    border-radius: 10px;
  }
  .testsListCont .centreCard .testsList {
    display: flex;
    flex-direction: column;
  }
  .testsListCont .centreCard .testsList .test {
    padding: 20px;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    grid-gap: 1em;
    text-align: left;
  }
  .testsListCont .centreCard .testsList .test .onlyMobile {
    display: inline;
  }
  .testsListCont .centreCard .testsList .test .index {
    display: none;
  }
  .testsListCont .centreCard .testsList .test .duration {
    text-align: left;
  }
  .testsListCont .centreCard .testsList .test .buttons {
    margin-top: 0;
  }
  .testsListCont .centreCard .testsList .test:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: none;
  }
  .testsListCont .centreCard .testsList .test:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
/*# sourceMappingURL=TestsListComponent.css.map */
.singleTestCont .title {
  text-align: center;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10vw;
  text-align: left;
  font-size: 2rem;
}

.singleTestCont .title button {
  font-size: 1rem;
  padding: 10px 30px;
  background-color: #00c851;
  border: 2px solid #00c851;
  color: #fff;
  border-radius: 5px;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.singleTestCont .title button:hover {
  background-color: #007e33;
  border-color: #007e33;
}

.singleTestCont .centreCard {
  margin: 3em 10vw;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (2fr 1fr)[1];
      grid-template-columns: repeat(1, 2fr 1fr);
  grid-gap: 2em;
}

.singleTestCont .centreCard.full {
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
}

.singleTestCont .centreCard .left {
  box-shadow: 18px 18px 36px #bebebe, -18px -18px 36px #ffffff;
  border-radius: 20px;
}

.singleTestCont .centreCard .left section {
  padding: 20px 30px 0;
  display: none;
}

.singleTestCont .centreCard .left section ul {
  margin: 2em 0 0 1.5em;
  list-style-type: none;
}

.singleTestCont .centreCard .left section ul li p {
  width: calc(80vw - 60px - 1em);
  word-wrap: break-word;
  color: #727070;
  font-size: 0.95rem;
  margin-top: 0.5em;
  margin-left: 1em;
}

.singleTestCont .centreCard .left section ul li p a {
  text-decoration: underline !important;
}

.singleTestCont .centreCard .left section ul li strong {
  font-weight: 500;
}

.singleTestCont .centreCard .left section ul li::before {
  content: "\f105";
  font-family: "FontAwesome";
  margin-right: 0.5em;
}

.singleTestCont .centreCard .left section ul li:not(:first-child) {
  margin-top: 0.5em;
}

.singleTestCont .centreCard .left section.active {
  display: block;
}

.singleTestCont .centreCard .left section .questionImage {
  width: 100%;
  border-radius: 10px;
}

.singleTestCont .centreCard .left section .clearSelection button {
  border-radius: 5px;
  padding: 7px 10px;
  font-size: 0.8rem;
  margin-top: 1em;
  color: #191d34;
  border: 2px solid #191d34;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.singleTestCont .centreCard .left section .clearSelection button:hover {
  color: #fff;
  background-color: #191d34;
}

.singleTestCont .centreCard .left section .hiddenRadio {
  position: relative;
  margin: 1em 0 1.5em;
}

.singleTestCont .centreCard .left section .hiddenRadio::after {
  position: absolute;
  content: "Clear Selection";
  top: -100%;
  left: -50%;
  white-space: nowrap;
  border-radius: 5px;
  padding: 7px 10px;
  font-size: 0.8rem;
  margin-top: 1em;
  color: #191d34;
  border: 2px solid #191d34;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
  z-index: 1;
}

.singleTestCont .centreCard .left section .hiddenRadio:hover::after {
  color: #fff;
  background-color: #191d34;
}

.singleTestCont .centreCard .left section .options {
  margin-top: 1.5em;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
}

.singleTestCont .centreCard .left section .navigation {
  margin: 1.5em auto 0;
  padding-bottom: 30px;
  text-align: center;
}

.singleTestCont .centreCard .left section .navigation button {
  border-radius: 5px;
  padding: 10px 30px;
  color: #fff;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.singleTestCont .centreCard .left section .navigation button.startBtn {
  background-color: #00c851;
  border: 2px solid #00c851;
}

.singleTestCont .centreCard .left section .navigation button.startBtn:hover {
  background-color: #007e33;
  border: 2px solid #007e33;
}

.singleTestCont .centreCard .left section .navigation button.prevBtn, .singleTestCont .centreCard .left section .navigation button.nextBtn {
  background-color: #33b5e5;
  border: 2px solid #33b5e5;
}

.singleTestCont .centreCard .left section .navigation button.prevBtn:hover, .singleTestCont .centreCard .left section .navigation button.nextBtn:hover {
  background-color: #0099cc;
  border: 2px solid #0099cc;
}

.singleTestCont .centreCard .left section .navigation button.nextBtn {
  margin-left: 1em;
}

.singleTestCont .centreCard .left section .navigation button:disabled {
  cursor: not-allowed;
}

.singleTestCont .centreCard .right {
  display: flex;
  flex-direction: column;
}

.singleTestCont .centreCard .right .timer {
  padding: 30px 30px;
  background-color: #fff;
  box-shadow: 18px 18px 36px #bebebe, -18px -18px 36px #ffffff;
  border-radius: 20px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
}

.singleTestCont .centreCard .right .questionBtns {
  padding: 30px 30px;
  background-color: #fff;
  box-shadow: 18px 18px 36px #bebebe, -18px -18px 36px #ffffff;
  border-radius: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[6];
      grid-template-columns: repeat(6, 1fr);
  grid-gap: 1em;
  margin-top: 2em;
}

.singleTestCont .centreCard .right .questionBtns .question {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #191d34;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.singleTestCont .centreCard .right .questionBtns .question.active, .singleTestCont .centreCard .right .questionBtns .question:hover {
  background-color: #191d34;
  color: #fff;
}

.singleTestCont .centreCard .smallTitle {
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1.5em;
}

.singleTestCont .centreCard .singleQuestion strong {
  font-weight: 500;
}

.singleTestCont .centreCard .singleQuestion .question {
  margin: 0.5em 0;
  font-size: 0.95rem;
  color: #727070;
}

.singleTestCont .centreCard .singleQuestion .explanation {
  margin-top: 0.5em;
}

.singleTestCont .centreCard .singleQuestion .explanation p {
  word-wrap: break-word;
  font-size: 0.9rem;
  color: #727070;
}

.singleTestCont .centreCard .singleQuestion .options {
  margin: 1.5em 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
  font-size: 0.9rem;
}

.singleTestCont .centreCard .singleQuestion .green {
  color: #00c851;
}

@media only screen and (max-width: 992px) {
  .singleTestCont .title {
    padding: 0 20px;
    font-size: 1.7rem;
  }
  .singleTestCont .title button {
    padding: 7px 10px;
    font-size: 0.9rem;
  }
  .singleTestCont .centreCard {
    margin: 2em 20px 3em;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    font-size: 0.9rem;
  }
  .singleTestCont .centreCard .left {
    order: 2;
    border-radius: 10px;
  }
  .singleTestCont .centreCard .left section {
    padding: 10px 30px 0;
  }
  .singleTestCont .centreCard .left section ul {
    margin-left: 0;
  }
  .singleTestCont .centreCard .left section ul li p {
    width: calc(100vw - 40px - 60px - 1em);
    font-size: 0.9rem;
  }
  .singleTestCont .centreCard .left section .navigation {
    margin: 1.5em auto 0;
    padding-bottom: 30px;
  }
  .singleTestCont .centreCard .left section .navigation button {
    padding: 7px 10px;
    font-size: 0.8rem;
  }
  .singleTestCont .centreCard .right {
    order: 1;
  }
  .singleTestCont .centreCard .right .timer {
    border-radius: 10px;
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 600px) {
  .singleTestCont .centreCard .singleQuestion .question {
    font-size: 0.9rem;
  }
  .singleTestCont .centreCard .singleQuestion .explanation p {
    font-size: 0.9rem;
  }
  .singleTestCont .centreCard .singleQuestion .options {
    margin: 1.5em 0;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    grid-gap: 1em;
    font-size: 0.9rem;
  }
}
/*# sourceMappingURL=SingleTestComponent.css.map */
.leaderboardCont .title {
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.leaderboardCont .centreCard {
  background-color: #fff;
  box-shadow: 18px 18px 36px #bebebe, -18px -18px 36px #ffffff;
  margin: 3em 15vw;
  border-radius: 20px;
  text-align: center;
}

.leaderboardCont .centreCard .leadersList {
  display: flex;
  flex-direction: column;
}

.leaderboardCont .centreCard .leadersList .header {
  position: static;
  padding: 30px;
  color: #fff;
  background-color: #eb0808;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: inherit;
}

.leaderboardCont .centreCard .leadersList .header .left {
  display: flex;
  align-items: center;
  text-align: left;
}

.leaderboardCont .centreCard .leadersList .header .left i {
  font-size: 2rem;
}

.leaderboardCont .centreCard .leadersList .header .left .testDetails {
  margin-left: 1.5em;
}

.leaderboardCont .centreCard .leadersList .header .left .testDetails .name {
  font-size: 1.3rem;
  font-weight: 500;
}

.leaderboardCont .centreCard .leadersList .header .left .testDetails .marks {
  font-size: 0.85rem;
}

.leaderboardCont .centreCard .leadersList .header .right .date {
  font-size: 1.3rem;
  font-weight: 500;
}

.leaderboardCont .centreCard .leadersList .leader {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr 2fr 1fr 1fr)[1];
      grid-template-columns: repeat(1, 1fr 2fr 1fr 1fr);
  align-items: center;
  text-align: center;
  padding: 30px 50px;
}

.leaderboardCont .centreCard .leadersList .leader .onlyMobile {
  display: none;
}

.leaderboardCont .centreCard .leadersList .leader .sno {
  text-align: left;
}

.leaderboardCont .centreCard .leadersList .leader .leaderDetails {
  display: flex;
  align-items: center;
  text-align: left;
}

.leaderboardCont .centreCard .leadersList .leader .leaderDetails .badge {
  margin-right: 1em;
}

.leaderboardCont .centreCard .leadersList .leader .leaderDetails .badge i {
  font-size: 2rem;
}

.leaderboardCont .centreCard .leadersList .leader .leaderDetails .badge.gold {
  color: #ffd700;
}

.leaderboardCont .centreCard .leadersList .leader .leaderDetails .badge.silver {
  color: #aaa9ad;
}

.leaderboardCont .centreCard .leadersList .leader .leaderDetails .badge.bronze {
  color: #a97142;
}

.leaderboardCont .centreCard .leadersList .leader .leaderDetails .details .name {
  font-size: 1.1rem;
  font-weight: 500;
}

.leaderboardCont .centreCard .leadersList .leader .leaderDetails .details .branch {
  font-size: 0.9rem;
}

.leaderboardCont .centreCard .leadersList .leader .score {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: right;
}

.leaderboardCont .centreCard .leadersList .leader:nth-child(even) {
  background-color: #f2f2f2;
}

.leaderboardCont .centreCard .leadersList .leader:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.leaderboardCont .centreCard .viewAllBtn {
  font-size: 1rem;
  padding: 10px 30px;
  background-color: #00c851;
  border: 2px solid #00c851;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.leaderboardCont .centreCard .viewAllBtn:hover {
  background-color: #007e33;
  border-color: #007e33;
}

@media only screen and (max-width: 992px) {
  .leaderboardCont .title {
    font-size: 1.7rem;
  }
  .leaderboardCont .centreCard {
    margin: 3em 20px;
    border-radius: 10px;
  }
  .leaderboardCont .centreCard .leadersList {
    display: flex;
    flex-direction: column;
  }
  .leaderboardCont .centreCard .leadersList .header {
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .leaderboardCont .centreCard .leadersList .header .right {
    margin-top: 1.5em;
  }
  .leaderboardCont .centreCard .leadersList .header .right .date {
    text-align: right;
  }
  .leaderboardCont .centreCard .leadersList .leader {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    align-items: center;
    text-align: left;
    padding: 20px;
  }
  .leaderboardCont .centreCard .leadersList .leader strong {
    font-weight: 500;
  }
  .leaderboardCont .centreCard .leadersList .leader .onlyMobile {
    display: inline;
  }
  .leaderboardCont .centreCard .leadersList .leader .sno {
    display: none;
  }
  .leaderboardCont .centreCard .leadersList .leader .leaderDetails .details .branch {
    white-space: nowrap;
  }
  .leaderboardCont .centreCard .leadersList .leader .time {
    margin-top: 1em;
  }
  .leaderboardCont .centreCard .leadersList .leader .score {
    margin-top: 1em;
    text-align: right;
  }
  .leaderboardCont .centreCard .leadersList .leader:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .leaderboardCont .centreCard .viewAllBtn {
    padding: 7px 10px;
    font-size: 0.9rem;
  }
}
/*# sourceMappingURL=LeaderboardComponent.css.map */
.answerKeyCont .title {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10vw;
  text-align: left;
  font-size: 2rem;
}

.answerKeyCont .title button {
  font-size: 1rem;
  padding: 10px 30px;
  background-color: #00c851;
  border: 2px solid #00c851;
  color: #fff;
  border-radius: 5px;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.answerKeyCont .title button:hover {
  background-color: #007e33;
  border-color: #007e33;
}

.answerKeyCont .centreCard {
  margin: 3em 10vw;
  box-shadow: 18px 18px 36px #bebebe, -18px -18px 36px #ffffff;
  border-radius: 20px;
  padding: 30px;
}

.answerKeyCont .centreCard .smallTitle {
  font-size: 1.3rem;
  font-weight: 500;
}

.answerKeyCont .centreCard .smallTitle .green {
  color: #00c851;
}

.answerKeyCont .centreCard .smallTitle .red {
  color: #ff4444;
}

.answerKeyCont .centreCard .questions .singleQuestion {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (50%)[2];
      grid-template-columns: repeat(2, 50%);
  grid-gap: 1em;
}

.answerKeyCont .centreCard .questions .singleQuestion strong {
  font-weight: 500;
}

.answerKeyCont .centreCard .questions .singleQuestion .left .question {
  margin: 0.5em 0;
  font-size: 0.95rem;
  color: #727070;
}

.answerKeyCont .centreCard .questions .singleQuestion .right .explanation {
  margin-top: 0.5em;
}

.answerKeyCont .centreCard .questions .singleQuestion .right .explanation p {
  word-wrap: break-word;
  font-size: 0.9rem;
  color: #727070;
}

.answerKeyCont .centreCard .questions .singleQuestion .options {
  margin: 1.5em 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
  font-size: 0.9rem;
}

.answerKeyCont .centreCard .questions .singleQuestion .green {
  color: #00c851;
}

.answerKeyCont .centreCard .questions .singleQuestion .red {
  color: #ff4444;
}

.answerKeyCont .centreCard .questions .singleQuestion:not(:first-child) {
  margin-top: 2em;
}

@media only screen and (max-width: 992px) {
  .answerKeyCont .title {
    padding: 0 20px;
    font-size: 1.7rem;
  }
  .answerKeyCont .title button {
    padding: 7px 10px;
    font-size: 0.9rem;
  }
  .answerKeyCont .centreCard {
    margin: 2em 20px 3em;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    font-size: 0.9rem;
    padding: 20px;
  }
  .answerKeyCont .centreCard .questions .singleQuestion {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    grid-gap: 1em;
    align-items: center;
  }
  .answerKeyCont .centreCard .questions .singleQuestion .right .explanation {
    margin-top: 1em;
  }
  .answerKeyCont .centreCard .questions .singleQuestion .right .explanation p {
    margin-top: 0.3em;
    font-size: 0.85rem;
    max-width: calc(100vw - 40px - 40px);
  }
  .answerKeyCont .centreCard .questions .singleQuestion .options {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 1.5em;
  }
}
/*# sourceMappingURL=AnswerKeyComponent.css.map */
.rollTheDiceCont {
  display: -ms-grid;
  display: grid;
  place-items: center;
  padding: 50px 0;
}

.rollTheDiceCont .rollForm form {
  width: 30vw;
}

.rollTheDiceCont .rollRiddles {
  width: 30vw;
}
/*# sourceMappingURL=RollTheDice.css.map */
.voiceMessagesCont {
  padding: 50px 10vw;
}

.voiceMessagesCont .namesList {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  grid-gap: 3em;
  margin-top: 3em;
}

.voiceMessagesCont .namesList .singleName {
  display: -ms-grid;
  display: grid;
  place-items: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
  box-shadow: 6px 6px 12px #bdbcbc, -6px -6px 12px #ffffff;
  border-radius: 10px;
  height: 100px;
  cursor: pointer;
  transition: 0.3s all ease;
}

.voiceMessagesCont .namesList .singleName:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.voiceMessagesCont .voiceCont,
.voiceMessagesCont .riddleCont {
  margin-top: 3em;
}
/*# sourceMappingURL=VoiceMessages.css.map */
.scratchCardCont {
  display: -ms-grid;
  display: grid;
  place-items: center;
  padding: 50px 0;
}

.scratchCardCont .scratchCard {
  position: relative;
  border: 3px solid yellow;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.scratchCardCont .canvas {
  position: absolute;
  top: 0;
}

.scratchCardCont .cardContent {
  padding: 20px;
}
/*# sourceMappingURL=ScratchCard.css.map */
.journal-header {
  margin: 0;
  padding: 0;
}

.journal-header .imgContent {
  height: 40vh;
  width: 100%;
  background-image: url(/static/media/wallpaper.5849dc12.jpg);
  background-size: cover;
  background-position: center;
}

.journal-header h4 {
  text-align: center;
  padding: 1em 0;
}

.first-con {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 900px) {
  .first-con {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
}
/*# sourceMappingURL=JournalsComponent.css.map */
.journalsCard{
    border-radius: 1em;
    box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
    /* border: 1px solid red; */
    margin: 3em auto 5em;
    width: 300px;
}
.card-cont{
    padding: 1em;
}

.journalsCard img{
    height:200px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.App {
  font-family: "Poppins", sans-serif;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #dfdbdb;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 10px;
}

.row-header {
  margin: 0px auto;
  padding: 0px auto;
}

.row-content {
  margin: 0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height: 400px;
}


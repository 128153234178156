.forgot-password-container {
  margin: 20px;
  position: relative;
  min-height: 70vh;
}

.forgot-password-card {
  margin: 20px auto;
  padding: 10px;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  max-width: 600px;
  /* width: 40%; */
}

hr.solid {
  border-top: 3px solid #bbb;
}

.forgot-password-card-title {
  font-size: 28px;
  text-align: center;
  font-weight: 700;
}

.forgot-password-card-subtitle {
  font-size: 24px;
  text-align: center;
  font-weight: 550;
}

.forgot-password-form {
  padding: 25px;
  text-align: center;
}

.reset-btn{ 
  background-color: #dc3545 !important;
  border-color: #dc3545 !important
}
.cardCont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
}
.singleCard {
  transition: 0.3s all ease;
}
.singleCard:hover {
  transform: scale(1.03);
}
.cardImage {
  width: 100%;
  height: 270px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.cardBody {
  border: 1px solid #c0c0c0 !important;
  border-top: 0;
  padding: 15px 15px 0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  min-height: 200px;
}
.cardBody h3 {
  font-family: "Barlow Condensed", sans-serif;
}
.cardBody p {
  text-align: justify;
  font-size: 0.95em;
}

@media only screen and (max-width: 992px) {
  .cardCont {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media only screen and (max-width: 768px) {
  .cardCont {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .cardBody {
    min-height: 0;
  }
}

.banner_text_iner {
  color: antiquewhite;
  font-family: "Sango-Light";
}
.h3 {
  margin-right: 150px;
}
.typewriter h3 {
  text-align: center;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The Typewriter Cursor */
  white-space: normal; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.1em; /* Adjust as needed */
  animation: typing 5s steps(80, end) forwards, blink-caret 0.75s step-end 40;
}

/* The Typing Effect */
@keyframes typing {
  from {
    width: 0%;
  }
  to {
    width: 100%;
    border: none;
  }
}

/* The Typewriter Cursor Effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.page-enter {
  position: fixed;
  opacity: 0.01;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}

.loginText {
  font-family: "Poppins", sans-serif;
}
.headerBtn {
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #191d34;
  color: #fff;
  font-size: 0.9em;
  white-space: nowrap;
  outline: none !important;
  cursor: pointer;
  transition: 0.3s all ease;
}
.headerBtn:hover {
  color: #191d34;
  background-color: #fff;
}
.ctaBtn {
  padding: 10px 45px;
  border-radius: 25px;
  background-color: #4dbb39;
  color: #fff;
  font-family: "Poppins", sans-serif;
  border: none;
  outline: none !important;
  cursor: pointer;
  transition: 0.3s all ease;
}
.ctaBtn:hover {
  background-color: #fff;
  color: #4dbb39;
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .loginText {
    font-size: 0.8em;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.btn {
  margin: 10px 10px 0 0;
}

.app {
  width: 400px;
  margin: 0 auto;
  height: 100vh;
}

/* Contact.js */
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4em;
}

textarea {
  padding: 20px;
  border-radius: 3px;
  margin: 20px 0;
  border: 1px solid lightgray;
  max-width: 1400px;
  height: 150px;
  outline: none;
}

textarea:focus {
  border: 1px solid #000;
}

.form > button {
  padding: 10px 45px;
  background-color: #02026e;
  border-radius: 5px;
  color: #fff;
  margin-top: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

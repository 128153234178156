footer {
  background-color: #191d34;
  color: #fff;
  padding: 50px 20px 25px;
  font-size: 16px;
}
.firstColumn {
  text-align: justify;
}
.firstColumn p {
  font-size: 0.9em;
  margin-top: 1em;
}
.whiteLogo {
  height: 90px;
}
.heading {
  border-left: 3.5px solid #fff;
  padding-left: 10px;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: normal;
}
.list {
  list-style-type: none;
  margin: 30px 0 0 14px;
}
.list li {
  display: grid;
  grid-template-columns: 1fr 20fr;
  align-items: center;
  grid-column-gap: 1em;
  color: #fff;
  font-size: 0.9em;
  transition: 0.3s all ease, 0s color ease;
}
.list li:hover {
  margin-left: 10px;
  font-weight: 500;
}
.list li:not(:first-child) {
  margin-top: 10px;
}
.list li a {
  color: #fff;
  text-decoration: none;
}
.socialIcons {
  display: flex;
  justify-content: center;
}
.socialIcon:not(:first-child) {
  margin-left: 1.2em;
}
.socialIcon a {
  color: #fff;
}
.socialIcon a:hover {
  color: #ff7e5f;
}
.socialIcon i {
  font-size: 1.4em;
}
.developedBy {
  display: flex;
  justify-content: center;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.1em;
  margin-top: 1em;
}
.developedBy a {
  margin-left: 0.3em;
  color: #ff7e5f;
}
.developedBy a:hover {
  color: #ff7e5f;
  text-decoration: underline !important;
}
.developedBy a:nth-child(3) {
  margin-right: 0.3em;
}

@media only screen and (max-width: 992px) {
  footer {
    padding-top: 0;
  }
  .row > div {
    margin-top: 2.5em;
  }
}

.row-header {
  margin: 0 auto;
  padding: 0 auto;
}
.journalSectionHeading{
  color: red;
  padding: 2em 0 1em;
  font-size: 3em;
}
.row-content {
  margin: 0 auto;
  padding: 50px 0;
  border-bottom: 1px ridge;
  min-height: 400px;
}

.jumbotron {
  padding: 180px 0 70px !important;
  margin: 0 auto !important;
  background-image: url("../../img/faq.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  z-index: 1;
  border-radius: 0% 0% 50% 50% / 0% 0% 15% 15% !important;
}

address {
  font-size: 80%;
  margin: 0px;
  color: #0f0f0f;
}

.btn_2 {
  display: inline-block;
  padding: 14px 30px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 25px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #0b025f;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
}
.btn_3 {
  display: inline-block;
  padding: 14px 30px;
  border-radius: 50px;
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  margin-top: 25px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border :2px solid #0b025f;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
}

.btn_1 {
  display: inline-block;
  padding: 14px 30px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 25px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #4dbb39;
  font-weight: 500;
  letter-spacing: 1px;
}

.btn_3:hover{
  background-color: #0b025f;
  color:#FFF;
}
.btn_1:hover,
.btn_2:hover {
  color: #4dbb39;
  background-color: #fff;
  text-decoration: none;
}

.about_text {
  padding: 20px 0 0;
}

.faq_part {
  padding: 40px 0;
  color: #000000;
}

.blockquote0 {
  background-color: #000f52;
  color: white;
  padding: 10px 0 10px 25px;
}

.blockquote1 {
  background-color: #000f52;
  padding: 0 0 30px 80px;
}

.homeSection {
  margin: 6em 2em;
}

.sectionImage {
  margin: 10px;
}

.faqs {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.faqs .faq {
  margin: 15px 5px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;

  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;

  background-image: url("./arrow-down-mint.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

.servicesSection {
  margin: 5em 2em 2em;
}
.serviceCont {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;
}
.services {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
  text-align: center;
  font-size: 16px;
  padding: 20px 0;
  min-height: 400px;
  transition: 0.3s all ease;
}
.services:hover {
  background-color: #4dbb39;
  color: #fff;
}
.services:hover .line {
  background-color: #fff;
}
.services i {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.entinner {
  padding: 0 20px;
  text-align: center;
}

.services .line {
  height: 3px;
  width: 25%;
  background-color: #fe5f28;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1em;
}
.services h4 {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600 !important;
  text-transform: uppercase;
  font-weight: bold;
}
.services p {
  margin-top: 1em;
  text-align: justify;
}

@media only screen and (max-width: 992px) {
  .serviceCont {
    grid-template-columns: repeat(1, 1fr);
  }
  .services {
    min-height: 0;
  }
}

@media only screen and (max-width: 768px) {
  .homeSection {
    margin: 3em 1.5em;
  }

  .servicesSection {
    margin: 5em 1.5em 2em;
  }
}

.personCard {
  position: relative;
  width: 300px;
  box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
  border-radius: 10px;
  margin: 0 auto 1.5em;
  overflow: hidden;
}
.personImage {
  width: 100%;
  max-height: 360px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: 0.3s all ease;
}
.personDetails {
  color: #000;
  text-decoration: none;
  text-align: center;
  padding: 20px 20px 7px;
}
.personDetails a {
  color: #000;
  text-decoration: none;
}
.personPost {
  font-weight: bold;
}
.personBranch {
  position: absolute;
  height: 100%;
  width: 100%;
  /* background-color: rgba(255, 126, 95, 0.9); */
  background-color: rgba(0, 123, 255, 0.9);
  border-radius: 10px;
  color: #fff;
  top: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: space-evenly;
  padding: 3rem 0;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  transition: 0.3s all ease;
}
.cta {
  display: block;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  border-radius: 5px;
  padding: 10px 0;
  width: 170px;
  margin: 1em auto 0;
  font-size: 0.8em;
  transition: 0.3s all ease;
}
.cta:hover {
  background-color: #fff;
  /* color: #ff7e5f; */
  color: #007bff;
  text-decoration: none;
}
.personCard:hover .personBranch {
  top: 0;
  opacity: 1;
  pointer-events: all;
}

.socials {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 2rem;
}

.socials > a {
  color: white;
}
.socials > a:hover {
  color: white;
}

.personBranch p:nth-child(2) {
  width: 60%;
}

.header-logo {
  margin-left: 30px;
}

.header-logo-img {
  height: 60px;
  width: 100px;
}

@media screen and (max-width: 999px) and (min-width: 750px) {
  .header-logo-img {
    height: 50px;
    width: 80px;
  }
}

.navbar-nav {
  align-items: center;
}
.nav-btn {
  color: black;
  margin: 0 10px;
  padding: 20px 10px;
}
.navbar-toggler {
  background: #b6b6b6;
}
.nav-btn:hover {
  color: #ff7e5f;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.banner_text_iner {
  color: antiquewhite;
}

.page-enter {
  position: fixed;
  opacity: 0.01;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}

.nav-link {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.2em;
  text-transform: uppercase;
  white-space: nowrap;
}

/* @media screen and (max-width: 1000px) {
  .nav-link {
    font-size: 16px;
  }
} */

@media screen and (max-width: 992px) and (min-width: 768px) {
  .nav-link {
    font-size: 0.9em;
  }
}

.login-container {
  margin: 20px;
  position: relative;
  min-height: 70vh;
}

.login-card {
  margin: 20px auto;
  padding: 10px;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  max-width: 600px;
  /* width: 40%; */
}

hr.solid {
  border-top: 3px solid #bbb;
}

.login-card-title {
  font-size: 28px;
  text-align: center;
  font-weight: 700;
}

.login-card-subtitle {
  font-size: 24px;
  text-align: center;
  font-weight: 550;
}

.margin-top-20{
  margin-top: 20px;
  text-align: right;
}

.login-form {
  padding: 25px;
  text-align: center;
}


@media only screen and (max-width: 900px) {
  .signUpCont .form .signupforgot .forgot {
    margin-top:0px;
    text-align: start;
  }
}
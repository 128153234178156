.journalsCard{
    border-radius: 1em;
    box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
    /* border: 1px solid red; */
    margin: 3em auto 5em;
    width: 300px;
}
.card-cont{
    padding: 1em;
}

.journalsCard img{
    height:200px;
}